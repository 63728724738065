$bodycolor: rgba(255, 255, 255, 0.75);
$navcolor: rgba(192, 192, 192, 0.9);
$navcolorsolid: rgb(192, 192, 192);
$footcolor: rgb(128, 128, 128);

//Hide the recaptcha button
.grecaptcha-badge { visibility: hidden; }

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	body {
		background-color: $bodycolor;
	}
	nav {
		backdrop-filter: blur(3px);
		margin: 0 auto;
		min-height: 80px;
		border-bottom: 2px solid black;
	}
	h1, h2 {
		color: black;
	}
	h1 {
		font-size: 3rem;
		padding: 12px 0;
	}
	.highlight {
		text-decoration: underline;
		transition: opacity 0.2s ease;
		color: rgb(185, 102, 6);
		&:hover {
			color: rgb(102, 57, 6);
		}
	}
	.home-img-1 {
		position: relative;
		&::before {
			content: ' ';
			filter: blur(3px);
			display: block;
			background-image: url("../assets/home/bg1.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			opacity: 0.6;
			width: 100%;
			height: 100vh;
		}
	}

	.centered {
		position: absolute;
		top: 52%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		font-size: 8vw;
		//color: rgb(12, 83, 3);
		text-shadow: -2px -2px 0 #fff, 0   -2px 0 #fff, 2px -2px 0 #fff, 2px  0   0 #fff, 2px  2px 0 #fff, 0    2px 0 #fff, -2px  2px 0 #fff, -2px  0   0 #fff;
	}
	header {
		padding-top: 100px;
	}
	section {
		&:nth-child(even) {
			background-color: rgb(240, 240, 240);
		}
		p {
			font-size: 1.2rem;
		}
	}

	hr {
		border-top: 2px solid black;
	}
	footer {
		background-color: $footcolor;
		color: white;
		width: 100%;
		border-top: 2px solid black;
		text-align: center;
		padding: 15px;
		margin-top: auto;
	}

	.nav-logo {
		background-image: url("../../public/favicon.ico");
		background-size: 32px 32px;
		margin-top: 2px;
		margin-right: 16px;
		width: 32px;
		height: 32px;
	}

	.navbar {
		background-color: $navcolor !important;
		font-weight: bold;
		font-size: 1.25rem;
		padding: 0.5rem 1.5rem;
		.nav-item {
			padding: 0.5rem 0;
			.nav-link, .fab {
				color: rgb(0, 0, 0);
				&:hover {
					color: rgba(0,0,0,0.7);
				}
			}
		}
		.navbar-brand {
			color: black;
			font-size: 1.5rem;
		}
	}

	#navbarBlocker {
		display: none !important;
	}

	#navbarSupportedContent {
		background-color: unset;

		ul {
			width: 100%;
			justify-content: flex-start;
		}
		li {
			text-align: center;
			min-width: 75px;
		}
		li:nth-child(n+5) {
			margin-left: auto;
		}

		.nav-link-active {
			text-decoration: underline;
		}
	}

	.mobile-icons .fab {
		float: right;
		margin-top: 5px;
		margin-left: 5px;
		font-size: 36px;
		width: 50px;
		text-align: center;
		text-decoration: none;
		color: rgba(0,0,0,.5);
		&:hover {
			text-decoration: none;
			color: rgba(0,0,0,.7);
		}
	}

	@media screen and (max-width: 991px) {
		nav {
			min-height: 55px;
			max-width: 100%;
		}
		.nav-logo {
			margin-top: 4px;
			margin-right: 0;
		}
		.navbar {
			background-color: $navcolorsolid !important;
			.nav-item {
				font-size: 1rem;
				padding: 0;
				&:last-of-type {
					margin-bottom: 10px;
				}
			}
		}

		.navbar-brand {
			margin-right: 0;
		}
		.centered {
			font-size: 12vw;
		}

		.navbar-toggler {
			border-color: rgba(0, 0, 0, 1) !important;
			outline: none !important;
			&:focus {
				background-color: rgba(0,0,0,0.1);
			}
		}
		header {
			padding-top: 80px;
		}
		.mobile-icons {
			margin: 12px auto 0 auto;
			background-color: unset !important;
			a, .fab {
				margin: 0 15px;
			}
		}

		#navbarSupportedContent {
			.navbar-nav {
				margin-top: 5px;
			}

			li {
				text-align: center;
				font-size: 150%;

				&:nth-child(odd) {
					background-color: rgb(167, 167, 167);
				}
				&:nth-child(even) {
					background-color: rgb(139, 139, 139);
				}
			}
		}
	}
}

.slideshow, .sh-modal {
	text-align: center;
	width: 65%;
	margin: 0 auto;

	.slide {
		display: none;
		img {
			width: 70%;
			margin: 0 0 25px 0;
			border: 3px solid black;
			transition: opacity 150ms ease;
			&:hover {
				opacity: 0.7;
				cursor: zoom-in;
			}
		}
	}
	
	.slidebutton {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		margin: 0 15px;
	}

	.slidebutton-nav {
		color: black;
		font-size: large;
		border-radius: 5px;
		width: 45px;
		height: 35px;
		background-color: lightgray;
		&:hover {
			background-color: silver;
		}
		@media screen and (max-width: 575px) {
			display: none;
		}
	}

	.button-active {
		background-color: gray;
	}

	button {
		display: inline-block;
		border: none;
		margin: 0;
		text-decoration: none;
		background-color: rgb(192, 192, 192);
		cursor: pointer;
		text-align: center;
		transition: background-color 250ms ease;
		-webkit-appearance: none;
		-moz-appearance: none;
		&:hover {
			background-color: gray;
		}
		&:focus {
			outline: none;
		}
	}

	@media screen and (max-width: 991px) {
		width: 65%;

		.slide img {
			width: 100%;
		}
	}

	@media screen and (max-width: 691px) {
		width: 95%;
	}
}
    
.sh-modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	border-radius: 0;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
	z-index: 1234; //Ensure it's above the nav, because bootstrap gives it 1030 index...
	transition: opacity 500ms ease;

	.slidebutton-nav {
		position: absolute;
		bottom: 3%;
		width: 13%;
		height: 8%;
		outline: 2px black solid;
		&:first-child {
			left: 25%;
		}
		&:last-child {
			right: 25%
		}
		&:focus {
			outline: 2px black solid;
		}
		@media screen and (max-width: 575px) {
			display: inline-block;
			width: 18%;
			height: 10%;
			&:first-child {
				left: 15%;
			}
			&:last-child {
				right: 15%;
			}
		}
	}
	
	.sh-modal-content {
		display: block;
		position: relative;
		margin: auto;
		width: unset;
		max-width: 90%;
		max-height: 90%;
		height: auto;
		cursor: zoom-out;
		top: 50%;
		transform: translateY(-50%);
	}
	
	.close {
		position: absolute;
		top: 0px;
		right: 25px;
		color: #f1f1f1;
		font-size: 100px;
		font-weight: bold;
	
		&:hover, &:focus {
			color: #bbb;
			text-decoration: none;
			cursor: pointer;
		}
	}
	
	@media screen and (max-width: 991px) {
		.sh-modal {
			padding-top: 80px;
		}
		.close {
			font-size: 60px;
			right: 25px;
		}
	}
}
